import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Контакты" />
      <h3>Добро пожаловать на страницу контактов центра развития и подготовки к экзаменам школьников "УмникУм"!</h3>

<p>Наш центр работает для вас с 9:00 до 19:00 ежедневно без выходных.</p>

<p>Вы можете связаться с нами по следующим телефонам:</p>

<ul>
  <li>+7(982)-296-68-55</li>
  <li>+7(993)-831-99-09</li>
</ul>

<p>Позвоните нам, чтобы записаться на бесплатную консультацию, узнать расписание занятий или задать любые другие вопросы о работе центра. Наши операторы с радостью вам помогут!</p>

<p>Также вы можете посетить один из наших офисов:</p>

<ul>
  <li>г. Златоуст, пр. Мира 28а (3 этаж, офис 3) (район Машзавода);</li>
  <li>г. Златоуст, ул. Румянцева 10-1а (район Вокзала).</li> 
</ul>

<p>В офисах вы сможете встретиться с нашими методистами, пройти тестирование и подобрать оптимальную программу подготовки к экзаменам для вашего ребенка.</p>

<p>Ждем вас в гости!</p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`